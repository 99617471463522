import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    Redirect,
    useHistory,
    useLocation
  } from "react-router-dom";

//  import awsoverImage from './images/awsoverview.jpg';

const About = () => {
    return  (
        <div align="center">
           <h2>About</h2> 
           <p align="center">The Webteria Systems Group Currently Under ReOrganization :
       
            <a href="https://webtopia.org/" >AWS System Services,</a>
            <a href="https://webtopia.org/" >Web Software Design,</a>
            <a href="https://webtopia.org/management/index.html" >Project Management</a>      
          
          </p>
        </div>
    )
}

export default About;
