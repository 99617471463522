// import logo from './logo.svg';
import './App.css';
// import React from 'react';
import React, { useState } from 'react';
// import { FaAlignRight } from 'react-icons/fa';
// import { Route , Switch, Link } from 'react-router-dom';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useHistory,
  useLocation
} from "react-router-dom";

// import { Player } from 'video-react';

import Home from'./Home';
import About from './About';
import Support from './Support';
import dcImage from './images/datacenter.png';
// import skyVid from './video/sky.mp4';
import seaVid from './video/sea.mp4';
import mwVid from './video/milkyway.mp4';
import artVid from './video/donnaart.mp4';
// import "../node_modules/video-react/dist/video-react.css"; // import css

<link rel="stylesheet" href="/css/video-react.css" />
 
function App() {

  const [toggleState, settoggleState] = useState(false);

  const Toggle = () => {
    // this.setState({toggle:!this.state.toggle})
    settoggleState(!toggleState);
  }

  return (


    <div className="App" align="center">
  
      <Router >
      <nav className="navBar">     
      <ul>
        <li><Link to ="/">Home</Link></li>    
        <li><Link to ="/about">About</Link></li>
        <li>< a href="https://dev.d2qxprmi1jsq2z.amplifyapp.com/"> AI </a></li>
        <li><a href="https://webtopiamedia.org/" >Music</a></li>
        <li><a href="https://dev.d2gmnwm4uhzoz5.amplifyapp.com/" >Boom Video Chat</a></li>
        <li><a href="https://dev.d2w0k7fhbhc8yn.amplifyapp.com/" >Tic-Top</a></li>
        <li><a href="https://dev.d13at6d1731eiq.amplifyapp.com" >WhatsThat</a></li>
        <li><a href="https://dev.d1utu41s6kpz8l.amplifyapp.com/" >InstaGrain</a></li>
        <li><a href="https://memoriesaremadeofthis.org/" >Mascot</a></li> 
        <li><a href="https://main.d1z493kfk5ibtq.amplifyapp.com/" >Weather</a></li>        
        <li><Link to ="/support" >Support</Link></li>
      </ul>
      
    </nav>
    
    <Switch>
      <Route path="/about" component={About} />
      <Route path="/support" component={Support} />
      <Route path="/" component={Home} />
    </Switch>
    </Router>

    
                
            <div align="center">   
                <h1> Webteria Data Centers Are Serving Tasty Apps </h1>         
                <a href="https://www.cloudtopia.org/">
                  <img alt="Support" src={ dcImage }/>
                </a>
            </div>
     <div align="center">
        <h1> to help people connect ..... </h1>
        <video id="displayvideo1"  controls autoPlay loop  width="400" height="400" >
        <source src={ seaVid } type="video/mp4" />
        </video>    
           
        <video id="displayvideo2"  controls autoPlay loop  width="400" height="400" >
        <source src={ mwVid } type="video/mp4" />
        </video> 
        <h1> From the Sea To the Sky ... All Night and all Day </h1>

        <video id="displayvideo3"  controls autoPlay loop  width="400" height="400" >
        <source src={ artVid } type="video/mp4" />
        </video> 
        <h1> Sponsored ART Exhibition </h1>
    </div>
     
    </div>

    


  );
}

export default App;
