import React from "react";
// import ReactDOM from 'react-dom';
import './App.css';


class Cform extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        email: 'Email here ...',
        comment: 'Comment here ...',
      };
      this.mySubmitHandler=this.mySubmitHandler.bind(this);

    }
    mySubmitHandler = (event) => {
      event.preventDefault();
    //  alert("You are submitting " + this.state.email + " and " + this.state.comment);
      let msg = "From: " + this.state.email + "  About: " + this.state.comment 
      this.sessend('dansaurina@gmail.com', 'dansaurina@gmail.com', msg  )
    }
    myChangeHandlerE = (event) => {
      //  let nam = event.target.name;
      //  let val = event.target.email;
        this.setState({email: event.target.value});
      //  alert("email " + this.email);
       // this.setState( this.email =  val);
    }

    myChangeHandlerC = (event) => {
      //  let nam = event.target.name;
       // let val = event.target.val;
       // this.setState( this.comment =  val);
       this.setState({comment: event.target.value});
    }

    // SES mail section ............
    	// AWS SES functions here ...................
       post = (url, body, callback) => {
        var req = new XMLHttpRequest();
        req.open("POST", url, true);
         req.setRequestHeader("Content-Type", "application/json");
         req.addEventListener("load", function () {
          if (req.status < 400) {
           callback(null, JSON.parse(req.responseText));
            } else {
            callback(new Error("Request failed: " + req.statusText));
            }
          });
        req.send(JSON.stringify(body));
      } // end of ses post ..............


    sessuccess = () => {
    console.log("EMail Sent. " );
    }

    seserror  = (err) => {
    console.log("SES Error " + err);
    }

     sessend  = ( name, email, content ) => {
 const urlses = 'https://tt1crydin3.execute-api.us-east-1.amazonaws.com/dev/email/send';

  const payload = {
        name: name,
        email: email,
       content: content
  }

      this.post(urlses, payload, function (err, res) {
       if (err) { return this.seserror(err) }
        //this.sessuccess()
        alert("Email sent to support !")
      });

  } // endof sessend .......................


// endof AWS SES functions


    // endof ses mail section .............


    render() {
      return (

        
        <form onSubmit={this.mySubmitHandler}>
        <p>Please enter your Email, and comment or question :</p>

        <p class="formfield">
        <input 
          name='email'
          type='text'
          placeholder="Email"
          onChange={this.myChangeHandlerE}
        />
        <p></p>
        <textarea 
        name='comment'
        rows='8'
        placeholder="Comment or Question"
        onChange={this.myChangeHandlerC} 
        />
        </p>
        
        <input
          type='submit'
        />
        
        </form>
        
      );
    }
  }

  export default Cform;