import React from 'react';

const Home = () => {
    return  (
        <div align="center">
          <h2>Home</h2>
          <p>Welcome to the Webteria Systems Group.</p>
    </div>
    )
}

export default Home;
