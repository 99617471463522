import React, { useEffect } from 'react';
import Cform from './Cform';

const Support = () => {

    useEffect(() => {
      
    }, []);
  
    return (
      <div align="center">
        <h2>Support</h2>
        <Cform />
      </div>
    );
  }
  
  export default Support;